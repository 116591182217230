import Address from './Address'
import Numeric from './Numeric'
import Percent from './Percent'

const Input = {
  Address,
  Percent,
  Numeric,
}

export default Input
